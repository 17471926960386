"use client";

import * as Sentry from "@sentry/nextjs";
import Error from "next/error";
import { useEffect } from "react";
export const metadata = {
  title: "Erreur  - Dynabuy Clubs d'affaires",
  description: "Bienvenue sur la page d'erreur des clubs d'affaires de Dynabuy"
};
export default function GlobalError({
  error,
  reset
}: {
  error: Error;
  reset: () => void;
}) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);
  return <html data-sentry-component="GlobalError" data-sentry-source-file="global-error.tsx">
      <body>
        <h2>Quelque chose s'est mal passé !</h2>
        <button onClick={() => reset()}>Revenir à l'accueil</button>
      </body>
    </html>;
}